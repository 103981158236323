import { render, staticRenderFns } from "./AddToCartButton.vue?vue&type=template&id=005af3d4&scoped=true&"
import script from "./AddToCartButton.vue?vue&type=script&lang=ts&"
export * from "./AddToCartButton.vue?vue&type=script&lang=ts&"
import style0 from "./AddToCartButton.vue?vue&type=style&index=0&id=005af3d4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "005af3d4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsPlusIcon24px: require('/var/www/packages/theme/components/icons/plus/PlusIcon24px.vue').default,IconsCartIcon24px: require('/var/www/packages/theme/components/icons/cart/CartIcon24px.vue').default,IconsCheckmark24px: require('/var/www/packages/theme/components/icons/checkmark/Checkmark24px.vue').default})
